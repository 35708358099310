img.amazo-logo {
    width: 2.5rem;
    height: 2.5rem;
}

hr.footer-hr {
    width: 100%;
    height: 1px;
    border: none;
    background: white;
    margin-top: 1rem;
}

.cfooter {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.logo {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

span.logo-name {
    font-size: 1rem;
    font-weight: 600;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}

.details {
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.7rem;
    gap: 1rem;
    font-style: italic;
}

span.det-spn {
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}

.pngline {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.pngline:hover {
    cursor: pointer;
}

img.icon {
    width: 25px;
}

.copyright {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 0.65rem;
    margin-top: 2rem;
}

@media screen and (max-width : 768px){
    .cfooter{
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        font-size: 1.7rem;
        gap: 2rem;
    }
}

@media screen and (max-width : 640px){
    .cfooter{
        justify-content: center;
    }
    .logo{
        display: none;
    }
}