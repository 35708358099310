.hero-container {
    padding: 3rem 2rem 1rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.middle-side {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}

img.hero-img {
    width: 30rem;
    position: absolute;
    bottom: 0;
}

.h-sides {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.left-top-items {
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}

.left-bottem-texts {
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
}

.big-text {
    font-weight: 800;
    font-size: 2.5rem;
}

.left-text {
    display: block;
}

.traffics {
    display: flex;
    flex-direction: column;
    text-align: right;
}

span.trfic-first {
    font-weight: 800;
    font-size: 2.5rem;
}

.customers {
    display: flex;
    flex-direction: column;
    text-align: right;
}

span.cust-first {
    font-weight: 800;
    font-size: 2.5rem;
}

.blue-circle {
    width: 30rem;
    display: block;
    height: 30rem;
    border-radius: 50%;
     z-index: -99;
    position: absolute;
    bottom: 0;
    background :  linear-gradient(135.74deg,#0bb5ff 14.09%,#6dd3fe 83.81%)!important;
}

.cart-2 {
    position: absolute;
    bottom: 25%;
    right: 5%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

img.shopping-bag-icon {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    border: 6px solid black;
}

.signup {
    display: flex;
    gap: 1rem;
    align-items: center;
    background: white;
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 15px;
    box-shadow: 0px 0px 100px goldenrod;;
}

span.signup-first-ch {
    display: block;
    width: 6rem;
}

img.arrow-icon {
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: cent;
    align-items: center;
    justify-content: center;
}

img.arrow-icon {
    width: 1.5rem;
}

@media screen and (max-width : 856px){
    .left-top-items,.left-text {
        font-size: 0.9rem;
        text-align: justify;
    }
    .big-text,.trfic-first,.cust-first{
        font-size: 1.5rem;
    }
    .blue-circle{
        position: absolute;
        width: 70%;
        height: 100%;
    }
    .hero-img{
        width: 70%;
        height: 100%;
    }
    .hero-container{
        grid-template-areas: 
        'left center center'
        'right right right'
        ;
    }
    .hero-container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem ;
    }
    .hero-container>:nth-child(2){
        grid-area: center;
    }
    .hero-container>:nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;
            }
}

@media screen and (max-width : 640px){
.blue-circle{
    top: 0;
    width: 16rem;
    height: 72%;
}
.middle-side>img{
    top: 3rem;
    width: 12rem;
    height: 15rem;
}
.cart-2 {
    transform: scale(.7);
    right: -2rem;
    bottom: 0;
}
}