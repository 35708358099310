.testi-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 2rem;
}

.wrapper {
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
}

img.hero2-img {
    width: 25rem;
    justify-self: center;
}

.contaier1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: right;
}

span.right-first {
    font-size: 2rem;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}

span.wrap-first {
    font-size: 2rem;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}

span.right-scnd {
    font-size: 0.8rem;
    display: block;
    text-transform: uppercase;
}

span.wrap-scnd {
    font-size: 0.8rem;
    display: block;
    text-transform: uppercase;
}

.review {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.carousal {
    width: 100%;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden.tCarousel {
    padding: 2rem;
}

.testi-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: white;
    border-radius: 0.5rem;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
}

img.testi-cart-img {
    position: absolute;
    width: 3rem;
    top: -1.5rem;
    /* left: 45%; */
}

span.testi-commnt {
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin-top: 2rem;
    text-align: center;
}

hr.testi-hr {
    height: 1px;
    width: 80%;
    background: rgb(198,198,198);
    border: none;
}

span.testi-cart-name {
    font-weight: 500;
}

@media screen and ( max-width: 640px) {
    .wrapper{
        grid-template-columns: 1fr;
    }
    .wrapper>img {
        width: 80vw;
    }
    .wrapper>:nth-child(3) ,.wrapper>:nth-child(1) {
        text-align:center;
    }
}