.p-cotainer {
    padding: 0 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: center;
}

img.heart-png {
    position: absolute;
    width: 8rem;
    left: 30%;
    top: -3rem;
}

.products {
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}

ul.menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.3rem;
}

ul.menu>li:hover{
    color: tomato;
    cursor: pointer;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(200px,1fr));
    height: 25rem;
    overflow-y: scroll;
    grid-gap: 2rem;
    justify-content: space-between;
}

img.img-p {
    top: 3rem;
    width: 6rem;
    height: 11rem;
    right: 0;
}

.product-itm {
    width: 12rem;
    height: 8rem;
    background-color: white;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding: 1rem;
    display: flex;
}

span.prod-name{
    font-size: 1.4rem;
    font-weight: 600;
}

span.prod-detail{
    font-size: 0.7rem
}

span.price-prod {
    font-weight: bold;
    font-size: 2rem;
}

.shop-prod {
    font-size: 0.6rem;
    border: 1px solid gray;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

@media screen and (max-width:856px) {
    .p-cotainer{
        gap: 1rem;
    }
    .p-cotainer>:nth-child(1){
        display: none;
    }
    .products{
        grid-template-columns: none;
        gap: 1rem;
    }
    .menu{
        flex-direction: row !important;
    }
}

@media screen and (max-width:640px){
    .menu{
        margin-left: -2rem;
        gap: 0.5rem;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.2rem;
    }

    .p-cotainer h1{
        text-align: center;
        
    }


}