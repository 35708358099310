.s-container {
    position: relative;
}

.s-container .swiper{
    width: 70%;
    height: 12rem;
    position: unset;
}

.s-container .swiper-slide{
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev{
    left: 6rem;
}

.s-container .swiper-button-next{
    right: 6rem;
}

.s-container .swiper-button-prev,.s-container .swiper-button-next{
    color: black;
}

.left-s {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name {
    display: flex;
    flex-direction: column;
}

span.name-first {
    font-size: 1.4rem;
    font-weight: 600;
}

span.scnd-text {
    font-size: 0.7rem;

}

span.price-sl {
    font-weight: bold;
    font-size: 2rem;
}

.shop-btn {
    font-size: 0.6rem;
    border: 1px solid gray;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

img.img-p {
    width: 20rem;
    transform: rotate(-20deg);
    position: absolute;
    bottom: -178px;
}

@media screen and (max-width : 856px) {
    .s-container .swiper{
      width: 90%;
      height: 10rem;
    }
    .s-container .swiper-button-prev,
    .s-container .swiper-button-next{
        display: none;
    }
}

