
.header-body {
    display: flex !important;
    padding: 1rem;
}

.logo-div {
    display: flex;
    align-items: center;
    flex: 1;
}

img.logo {
    width: 2.5rem;
    height: 2.5rem;
}

span.logo-span {
    font-weight: 600;
    padding: 10px;
}

img.cart-img {
    width: 1.5rem;
    height: 1.5rem;
}

.right-items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

ul.menu-ul {
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
}

li.menu-li:hover {
    color: #fe956f;
    cursor: pointer;
}

input.search-box {
    width: 7rem;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0.5rem;
    height: 30%;
}

img.menu-icon {
    width: 2rem;
    height: 2rem;
}

@media screen and (max-width : 856px){
    .search-box{
        display: none;
    }
    .right-items{
        gap: 1rem;
    }
}

@media screen and (max-width:640px) {
    .right-items{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: white;
        color: black;
        border-radius: 5px;
        flex-direction: column;
        padding: 1rem;
    }
  
    .menu-ul{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    .bars{
        display: block;
    }
    .search-box{
        display: none;
    }
}